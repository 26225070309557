import UserLoader from "./user/UserLoader";
import Tracking from "./utils/Tracking";
import View from "./view/View";
import LifecycleEvent from "./utils/LifecycleEvent";
import Env from "./config/Env";
import PageEmbeddedParams from "./params/PageEmbeddedParams";
import QueryParams from "./params/QueryParams";
import Logger from "./utils/Logger";

export default class LightningRecommend {
	//
	static loaded = false;

	static userId = null;
	static sessionId = null;

	static #extendMethods = {};
	static #view = null;

	static accessFromBot = false;

	static async load() {
		//
		try {
			if (LightningRecommend.loaded) {
				Logger.LogWarning("Load function can only be called once.");
				return;
			}
			LightningRecommend.loaded = true;

			if (!Env.init()) {
				Logger.LogWarning("Failed to initialize environment variables.");
				return;
			}

			let settingParam = PageEmbeddedParams.value.setting || {};

			let user;
			if (!LightningRecommend.accessFromBot) {
				user = await UserLoader.load();
			} else {
				user = UserLoader.createBotUser();
				if (settingParam) {
					settingParam.defaultLoading = "eager";
				}
			}
			if (!user) {
				Logger.LogError("Failed to load the user.");
				return;
			}

			LightningRecommend.userId = user.userId;
			LightningRecommend.sessionId = user.sessionId;
			LightningRecommend.#view = new View(LightningRecommend.userId, LightningRecommend.sessionId, LightningRecommend.#extendMethods);

			LightningRecommend.#addLifecycleEvent();

			LifecycleEvent.invoke("ready", null, {});

			Tracking.setGoodsHistory();
			Tracking.setContentsHistory();
			Tracking.setGoodsPvHistory();
			Tracking.setContentsPvHistory();

			if (settingParam.tracking === true && !LightningRecommend.accessFromBot) {
				Tracking.trackGoodsAccess(LightningRecommend.userId, LightningRecommend.sessionId, null);
				Tracking.trackContentsAccess(LightningRecommend.userId, LightningRecommend.sessionId);
				Tracking.trackCart(LightningRecommend.userId, LightningRecommend.sessionId);
				Tracking.trackOrder(LightningRecommend.userId, LightningRecommend.sessionId);
			}
			
			const params = QueryParams.getParams(location.href);
			const splitparams = QueryParams.splitLandingKey(params.landingkey);
			const validatedParams = QueryParams.validateParams(splitparams);
			if (validatedParams.landingkey == "aireco" && validatedParams.seq) {
				let goodIndex = validatedParams.seq - 1;
				let goodsAccessParams = PageEmbeddedParams.value.goodsAccess;
				if (goodsAccessParams) {
					Tracking.trackClick(LightningRecommend.userId, LightningRecommend.sessionId, "", goodsAccessParams.goodsCode, "mail", validatedParams["recommendtype"], goodIndex);
				}
			}

			if (settingParam.display === true) {
				await LightningRecommend.showRecommend();
			}

			LifecycleEvent.invoke("load", null, {});
		} catch (error) {
			Logger.LogError(error);
		}
	}

	static async showRecommend() {
		await LightningRecommend.#view.showRecommend();
	}

	static sendGoodsAccess(goodsCode) {
		if (goodsCode && LightningRecommend.userId && LightningRecommend.sessionId) {
			if (PageEmbeddedParams.value.setting.tracking === true) {
				// tracking:falseの場合は、商品アクセスを送信しない(警告ログも出さない)
				Tracking.trackGoodsAccess(LightningRecommend.userId, LightningRecommend.sessionId, goodsCode);
			}
		}
		else {
			Logger.LogWarning("Failed to send goods access. User information or goodsCode is not set.");
		}
	}

	static registerExtendMethods(methods) {
		Object.assign(LightningRecommend.#extendMethods, methods);
	}

	static addEventListener(type, listener) {
		LifecycleEvent.add(type, listener);
	}

	static removeEventListener(type, listener) {
		LifecycleEvent.remove(type, listener);
	}

	static #addLifecycleEvent() {
		//
		LightningRecommend.addEventListener("create", function (evt) {
			// 表示履歴を送信
			Tracking.trackRecommendOutput(LightningRecommend.userId, LightningRecommend.sessionId, evt.tagsCode, evt.goodsList);
		});
	}
}
